define("discourse/plugins/hosted-site/discourse/components/modal/admin-send-receipt", ["exports", "@ember/component", "@glimmer/component", "@ember/object", "@ember/service", "discourse/lib/ajax", "@ember/template-factory"], function (_exports, _component, _component2, _object, _service, _ajax, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <DModal
    @title="Send Receipt"
    @closeModal={{@closeModal}}
    class="hosted-send-receipt"
  >
    <:body>
      <p>
        Do you want your last receipt to be emailed to the current billing email
        address?
        <pre>{{@model.billingEmailAddress}}</pre>
      </p>
    </:body>
  
    <:footer>
      <DButton
        @translatedLabel="Yes"
        @action={{this.sendReceipt}}
        class="btn-primary"
      />
  
      <DButton
        @translatedLabel="Cancel"
        @action={{@closeModal}}
        class="btn-flat"
      />
    </:footer>
  </DModal>
  */
  {
    "id": "ihGJl120",
    "block": "[[[8,[39,0],[[24,0,\"hosted-send-receipt\"]],[[\"@title\",\"@closeModal\"],[\"Send Receipt\",[30,1]]],[[\"body\",\"footer\"],[[[[1,\"\\n    \"],[10,2],[12],[1,\"\\n      Do you want your last receipt to be emailed to the current billing email\\n      address?\\n      \"],[10,\"pre\"],[12],[1,[30,2,[\"billingEmailAddress\"]]],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[]],[[[1,\"\\n    \"],[8,[39,1],[[24,0,\"btn-primary\"]],[[\"@translatedLabel\",\"@action\"],[\"Yes\",[30,0,[\"sendReceipt\"]]]],null],[1,\"\\n\\n    \"],[8,[39,1],[[24,0,\"btn-flat\"]],[[\"@translatedLabel\",\"@action\"],[\"Cancel\",[30,1]]],null],[1,\"\\n  \"]],[]]]]]],[\"@closeModal\",\"@model\"],false,[\"d-modal\",\"d-button\"]]",
    "moduleName": "discourse/plugins/hosted-site/discourse/components/modal/admin-send-receipt.hbs",
    "isStrictMode": false
  });
  class AdminSendReceipt extends _component2.default {
    static #_ = (() => dt7948.g(this.prototype, "dialog", [_service.service]))();
    #dialog = (() => (dt7948.i(this, "dialog"), void 0))();
    async sendReceipt() {
      try {
        await (0, _ajax.ajax)("/admin/hosted_account/send_receipt.json");
        this.args.closeModal();
        this.dialog.alert("OK an email will be sent to you shortly. If you don't receive it, send an email to team@discourse.org.");
      } catch {
        this.args.closeModal();
        this.dialog.alert("There was an error. Please try again later, or send an email to team@discourse.org.");
      }
    }
    static #_2 = (() => dt7948.n(this.prototype, "sendReceipt", [_object.action]))();
  }
  _exports.default = AdminSendReceipt;
  (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, AdminSendReceipt);
});